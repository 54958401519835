import { FilesConfiguration } from "./contractsFilesConfiguration"

export const fileValid = (file: File, configuration: FilesConfiguration): string | undefined => {
    const index = file.name.lastIndexOf('.')
    const extension = file.name.substring(index).toLowerCase()

    const extensionIndex = configuration.fileExtensions.findIndex(x => x === extension)
    if (extensionIndex === -1) {
        return 'Недопустимое расширение файла.'
    }

    if (file.size < configuration.fileMinimumBytes) {
        return `Файл слишком маленького размера.`
    }

    if (file.size > configuration.fileMaximumBytes) {
        const maxMb = Math.round(configuration.fileMaximumBytes / 1024 / 1024)
        return `Файл должен быть менее ${maxMb}Мб.`
    }
}